import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

// footer component
export class FooterComponent implements OnInit {

  switch = 1;
  cur_year = 0;

  constructor() {
  }

  ngOnInit() {
    this.cur_year = new Date().getFullYear();
  }

  onChangeSwitch() {
    document.getElementById('style-switcher')?.classList.toggle('show')
    // this.switch == 1 ? this.switch = 0 : this.switch = 1;
  }
  onChangeColor(color: string) {
    document.getElementById('color-opt').setAttribute('href', 'assets/css/colors/' + color + '.css');
    // document.getElementById("theme_id").className = "";
    // document.getElementById('theme_id').classList.add('theme-'+color);
  }

  onChangeMode() {
    let theme = localStorage.getItem("theme");
    if (theme == "light" || theme == "") {
      document.body.setAttribute("data-bs-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.body.removeAttribute("data-bs-theme");
      localStorage.setItem("theme", "light");
    }
  }

}
