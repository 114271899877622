import { Component, Injectable, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

// header component

export class HeaderComponent implements AfterViewInit {
  sections = {};
  curentsection:any;
  constructor(private router: Router) { }

  ngOnInit() {

    window.onscroll = function () {
      myFunction()
    };
    var navbar1 = document.getElementById("navbar1");
    function myFunction() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbar1.style.backgroundColor = "#272a33";
        navbar1.style.padding = "10px";
      }
      else {

        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";
      }
    }

    this.curentsection = 'home'
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
    document.getElementById('menu_button').classList.toggle('collapsed');
  }

  ngAfterViewInit() {
  }

  onSectionChange(section: any) {
    this.curentsection = section
  }
}



