
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar1" >
    <div class="container" appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)" >
        <!-- LOGO -->
        <a class="navbar-brand logo text-uppercase" routerLink="/">
            <i class="mdi mdi-alien"></i>Hiric
        </a>

        <button class="navbar-toggler" (click)="toggleMenu()" type="button" id="menu_button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav navbar-center" id="mySidenav">
                <li class="nav-item">
                    <a data="id_home" [ngClass]="{'active':curentsection === 'home'}" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
                </li>
                <li class="nav-item">
                    <a data="id_features" [ngClass]="{'active':curentsection === 'features'}" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
                </li>
                <li class="nav-item">
                    <a data="id_services" [ngClass]="{'active':curentsection === 'services'}" [ngxScrollTo]="'#services'" class="nav-link">Services</a>
                </li>
                <li class="nav-item">
                    <a data="id_about" [ngClass]="{'active':curentsection === 'about'}" [ngxScrollTo]="'#about'" class="nav-link">About</a>
                </li>
                <li class="nav-item">
                    <a data="id_pricing" [ngClass]="{'active':curentsection === 'pricing'}" [ngxScrollTo]="'#pricing'" class="nav-link">Pricing</a>
                </li>
                <li class="nav-item">
                    <a data="id_blog" [ngClass]="{'active':curentsection === 'blog'}" [ngxScrollTo]="'#blog'" class="nav-link">Blog</a>
                </li>
                <li class="nav-item">
                    <a data="id_contact" [ngClass]="{'active':curentsection === 'contact'}" [ngxScrollTo]="'#contact'" class="nav-link">Contact</a>
                </li>
            </ul>
            <div class="nav-button ms-auto">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <button type="button"
                            class="btn btn-primary navbar-btn btn-rounded waves-effect waves-light">Try it Free</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>